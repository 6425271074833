<template>
  <div class="container mt-5">
    <a-row>
      <a-col :xs="{ span: 24 }" :md="{ span: 16 }" :lg="{ span: 22 }">
        <a-row type="flex" justify="space-between" class="mb-3">
          <a-col :xs="{ span: 24 }" :md="{ span: 2 }" :lg="{ span: 18 }">
            <h4>Xabarlar ro'yxati</h4>
          </a-col>
        </a-row>
        <a-table
          :columns="columns"
          :data-source="data"
          bordered
          :scroll="{ x: 300 }"
        >
          <template slot="name" slot-scope="text">
            {{ text }}
          </template>
          <template slot="deletetion" slot-scope="deletetion, id">
            <div class="d-flex">
              <a-button
                @click="deleteMessage(id.id)"
                class="bg-danger text-white"
                >O'chirish</a-button
              >
            </div>
          </template>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
const columns = [
  {
    title: 'Telefon Raqam',
    dataIndex: 'phone_number',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Email',
    className: 'column-money',
    dataIndex: 'email',
  },
  {
    title: 'Foydaluvchilarning xabarlari',
    className: 'column-money',
    dataIndex: 'message',
  },
  {
    title: "O'chirish",
    dataIndex: 'deleteMessage',
    scopedSlots: { customRender: 'deletetion' },
  },
];
import { mapActions } from 'vuex';
export default {
  components: {},

  methods: {
    ...mapActions({
      getAllContacts: 'contact/getAllContacts',
      deleteOneMessage: 'contact/deleteMessage',
    }),

    deleteMessage(id) {
      this.deleteOneMessage(id)
        .then(() => {
          this.$notification['success']({
            message: "O'chirildi",
            description: "Xabar o'chirildi",
          });
          this.loadAllData();
        })
        .catch(() => {
          this.$notification['error']({
            message: 'Xatolik',
            description: "Xabar o'chirilmadi",
          });
        });
    },

    loadAllData() {
      this.getAllContacts().then((res) => {
        this.data = res.map((item) => {
          return {
            key: item.id,
            id: item.id,
            message: item.message,
            phone_number: item.phone_number,
            email: item.email,
          };
        });
      });
    },
  },
  data() {
    return {
      columns: columns,
      data: [],
    };
  },

  created() {
    this.loadAllData();
  },
};
</script>
