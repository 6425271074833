<template>
  <div>
    <contact-list></contact-list>
  </div>
</template>

<script>
import ContactList from '../../components/Contacts/Contactlist.vue';
export default {
  components: {
    ContactList,
  },
};
</script>
